import { routeToUrl } from "@app-routes";
import { IconsType } from "@config/icons";
import { MyProfileFragment } from "@gql-schema";
import { AppInstance } from "@shared/utils/app-instance";
import { NewClickableProps } from "@ui/NewClickable";
import { buildArray } from "@uxf/core/utils/buildArray";
import { TranslateFunction } from "../../next-translate";

type SelectedClickableProps = "hash" | "disabled" | "href" | "id" | "onClick" | "target" | "dataTestId";

export interface IMenuItem extends Pick<NewClickableProps, SelectedClickableProps> {
    icon: IconsType;
    isActive?: boolean;
    menuKey: string;
    title: string;
    chip?: string;
}

export const getDefaultMenu = (t: TranslateFunction): IMenuItem[] => {
    return buildArray<IMenuItem>()
        .add({
            title: t("component-default-menu:my-houses"),
            icon: "blockOfFlats",
            href: routeToUrl("house-zone/house-community"),
            menuKey: "moje-domy",
        })
        .add({
            title: t("component-default-menu:community"),
            icon: "users",
            href: AppInstance.isSlovak ? undefined : routeToUrl("club-zone/community"),
            menuKey: "komunita",
            chip: AppInstance.isSlovak ? t("common:labels.we-prepare") : undefined,
            disabled: AppInstance.isSlovak,
        })
        .add({
            title: t("component-default-menu:magazine"),
            icon: "magazine",
            href: routeToUrl("magazine-zone/home"),
            menuKey: "magazin",
        })
        .add({
            title: t("component-default-menu:market-place"),
            icon: "market",
            href: routeToUrl("market-zone/market"),
            menuKey: "trziste",
        })
        .add({
            title: t("component-default-menu:support"),
            icon: "infoCircle",
            href: "https://poradna.sousede.cz/",
            menuKey: "poradna",
        });
};

export const getLandingPageMenu = (t: TranslateFunction): IMenuItem[] => {
    return buildArray<IMenuItem>()
        .add({
            title: t("component-landing-page-menu:about-neighbours"),
            icon: "houseAlt",
            href: routeToUrl("index"),
            menuKey: "",
            dataTestId: "navbar-o-sousedech",
        })
        .add({
            title: t("component-landing-page-menu:market-place"),
            icon: "market",
            href: routeToUrl("market-zone/market"),
            menuKey: "trziste",
            dataTestId: "navbar-trziste",
        })
        .add({
            title: t("component-landing-page-menu:magazine"),
            icon: "magazine",
            href: routeToUrl("magazine-zone/home"),
            menuKey: "magazin",
            dataTestId: "navbar-magazin",
        })
        .add({
            title: t("component-landing-page-menu:support"),
            icon: "infoCircle",
            href: "https://poradna.sousede.cz/",
            menuKey: "poradna",
            dataTestId: "navbar-poradna",
        });
};

export const getUserMenu = (t: TranslateFunction, profile: MyProfileFragment): IMenuItem[] => {
    const items: IMenuItem[] = [];

    if (profile.serviceCompanyAdmin && AppInstance.isCzech) {
        items.push({
            title: t("component-user-menu:service-zone"),
            icon: "serviceKey",
            href: routeToUrl("service-zone/entry"),
            menuKey: "servisni-zona",
        });
    }
    items.push({
        title: t("component-user-menu:notifications-overview"),
        icon: "megaphone",
        href: routeToUrl("profile-zone/notifications"),
        menuKey: "profil/prehled-notifikaci",
    });
    items.push({
        title: t("component-user-menu:my-events"),
        icon: "calendar",
        href: routeToUrl("profile-zone/my-events"),
        menuKey: "profil/udalosti",
    });
    items.push({
        title: t("component-user-menu:my-posts"),
        icon: "pencil",
        href: routeToUrl("profile-zone/my-wall-posts"),
        menuKey: "profil/moje-prispevky",
    });
    items.push({
        title: t("component-user-menu:profile-settings"),
        icon: "settings",
        href: routeToUrl("profile-zone/my-profile"),
        menuKey: "profil/nastaveni",
    });
    items.push({
        title: t("component-user-menu:logout"),
        icon: "share",
        href: routeToUrl("auth-zone/logout", { redirect: "/" }),
        menuKey: "odhlasit-se",
    });
    return items;
};

export const getUserSettingsMenu = (t: TranslateFunction): IMenuItem[] => {
    return [
        {
            title: t("component-user-settings-menu:personal-settings"),
            icon: "user",
            href: routeToUrl("profile-zone/personal-settings"),
            menuKey: "profil/nastaveni/osobni-nastaveni",
        },
        {
            title: t("component-user-settings-menu:email-and-mobile-notifications"),
            icon: "notifications",
            href: routeToUrl("profile-zone/my-profile-settings-notifications"),
            menuKey: "profil/nastaveni/notifikace",
        },
        {
            title: t("component-user-settings-menu:information-sharing"),
            icon: "shield",
            href: routeToUrl("profile-zone/my-profile-settings-sharing-info"),
            menuKey: "profil/nastaveni/sdileni-informaci",
        },
        {
            title: t("component-user-settings-menu:new-agreements"),
            icon: "mail",
            href: routeToUrl("profile-zone/my-profile-settings-news-subscription"),
            menuKey: "profil/nastaveni/odbery",
        },
        {
            title: t("component-user-settings-menu:leave-group-or-house"),
            icon: "leave",
            href: routeToUrl("profile-zone/my-profile-settings-leave-group"),
            menuKey: "profil/nastaveni/opustit-skupinu",
        },
    ];
};

export const getServiceCompanyMenu = (t: TranslateFunction, serviceCompanyId: number): IMenuItem[] => [
    {
        icon: "blockOfFlats",
        menuKey: "registrovane-domy",
        title: t("component-service-company-menu:houses"),
        href: routeToUrl("service-zone/registered-houses", { serviceCompanyId: serviceCompanyId }),
    },
    {
        icon: "comment",
        menuKey: "wall-posts",
        href: routeToUrl("service-zone/service-wall-posts", { serviceCompanyId: serviceCompanyId }),
        title: t("component-service-company-menu:pinboards"),
    },
    {
        icon: "megaphone",
        menuKey: "defects",
        href: routeToUrl("service-zone/service-defects", { serviceCompanyId: serviceCompanyId }),
        title: t("component-service-company-menu:defects"),
    },
    {
        icon: "infoCircle",
        menuKey: "activities",
        href: routeToUrl("service-zone/service-activities", { serviceCompanyId: serviceCompanyId }),
        title: t("component-service-company-menu:house-activities"),
    },
    {
        icon: "arrowToggle",
        menuKey: "cadastre",
        href: routeToUrl("service-zone/service-cadastre", { serviceCompanyId: serviceCompanyId }),
        title: t("component-service-company-menu:cadastre-changes"),
    },
    {
        icon: "calendar",
        menuKey: "events",
        href: routeToUrl("service-zone/service-events", { serviceCompanyId: serviceCompanyId }),
        title: t("component-service-company-menu:events"),
    },
    {
        icon: "mailInvite",
        menuKey: "distribuce-dokumentu",
        href: routeToUrl("service-zone/document-distribution-houses", { serviceCompanyId: serviceCompanyId }),
        title: t("component-service-company-menu:document-distribution"),
    },
    {
        icon: "hammer",
        menuKey: "insolvency",
        href: routeToUrl("service-zone/service-insolvency", { serviceCompanyId: serviceCompanyId }),
        title: t("component-service-company-menu:house-insolvencies"),
        disabled: true,
    },
    {
        icon: "team",
        menuKey: "zamestnanci",
        href: routeToUrl("service-zone/employees", { serviceCompanyId: serviceCompanyId }),
        title: t("component-service-company-menu:employees"),
    },
    {
        icon: "settings",
        menuKey: "nastaveni",
        title: t("component-service-company-menu:settings"),
        href: routeToUrl("service-zone/settings", { serviceCompanyId: serviceCompanyId }),
    },
    {
        disabled: false,
        icon: "pair",
        menuKey: "starlit",
        title: t("component-service-company-menu:starlit"),
        href: routeToUrl("service-zone/starlit", { serviceCompanyId: serviceCompanyId }),
    },
];
